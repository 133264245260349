import * as React from "react";
import { MDXRenderer } from "gatsby-plugin-mdx";
// import { Link, useStaticQuery, graphql } from "gatsby";
// import { StaticImage } from "gatsby-plugin-image";
import Lightbox from "./lightbox";
import TechIcons from "./techicons";

const Project = ({ project }) => {
    let descriptions = {};
    if (project.frontmatter.imageDescriptions) {
        descriptions = Object.assign({},
            ...project.frontmatter.imageDescriptions.map(img => {
                return { [img.image]: img.description };
            }),
        );
    }

    return (
        <article key={project.id} className={"bg-white shadow-md px-3 md:px-6 py-3 md:py-6 my-2 md:my-4 md:mx-0" + (project.frontmatter.company ? " mx-0" : " mx-2 border")}>
            <h2 className="font-bold text-2xl mb-4 text-main-dark relative">
                <span id={project.slug} className="absolute top-neg-offset"></span><span className="italic text-sm mr-0.5 hidden">Project:</span> {project.frontmatter.title}
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 md:gap-6">
                <div className="order-last md:order-none mt-4 md:mt-0">
                    <Lightbox project={project.slug} descriptions={descriptions} alignCenter={false} className="gap-3 mt-3" mainImage={project.frontmatter.mainImage} />
                </div>
                <div className="md:col-span-2 lg:col-span-3">
                    <div className="text-justify">
                        <MDXRenderer>
                            {project.body}
                        </MDXRenderer>
                    </div>
                    {(project.frontmatter.tech && Object.keys(project.frontmatter.tech).length > 0) && <div className="items-bottom mt-4 text-gray-500"><span className="text-xs mr-0.5">Tech stack:</span> <TechIcons tech={project.frontmatter.tech} /></div>}
                    {project.frontmatter.link && <p className="text-gray-500"><span className="text-xs mr-0.5">Link:</span> <a href={project.frontmatter.link} className="hover:font-bold" target="_blank">{project.frontmatter.link}</a></p>}
                    <p className="text-gray-500"><span className="text-xs mr-0.5">Year:</span> {project.time.getFullYear()}</p>
                </div>
            </div>
        </article>
    );
};

export default Project;
