import React, { useState } from "react";
import { graphql } from "gatsby";
import Layout from "../../components/layout";
import Project from "../../components/project";
import Company from "../../components/company";
import { PlusSmIcon, MinusSmIcon } from "@heroicons/react/outline";

const WorkPage = ({ data }) => {
    const [isOldOpen, setIsOldOpen] = useState(false);

    const toggleOld = ev => {
        ev.stopPropagation();
        setIsOldOpen(!isOldOpen);
    };

    // combine work and projects into tree structure, sort by date
    const work = data.projects.nodes
        .filter(p => p.frontmatter.company === "")
        .concat(data.companies.nodes)
        .map(w => {
            w.time = new Date(w.frontmatter.date);
            w.toTime = w.frontmatter.to ? new Date(w.frontmatter.to) : null;
            if (w.fields.source === "company") {
                // get corresponding nodes for current company
                w.children = data.projects.nodes
                    .filter(p => p.frontmatter.company === w.slug)
                    .map(w => {
                        w.time = new Date(w.frontmatter.date);
                        return w;
                    })
                    .sort((wa, wb) => new Date(wb.time) - new Date(wa.time));
            } else {
                w.children = null;
            }
            return w;
        })
        .sort((wa, wb) => new Date(wb.time) - new Date(wa.time));

    const renderNode = node => {
        if (node.fields.source === "company") {
            return <Company key={node.slug} company={node} />;
        } else {
            return <Project key={node.slug} project={node} />;
        }
    };

    return (
        <Layout pageTitle="work">
            <div className="max-w-5xl mx-auto md:mt-6 md:px-4 xl:px-0">
                {work.filter(n => n.frontmatter.old !== true).map(renderNode)}
                <div className="mt-8 ml-2 md:ml-0 font-bold text-2xl mb-4 text-main-dark cursor-pointer" onClick={toggleOld}>Older projects <PlusSmIcon className={"h-6 w-6 " + (isOldOpen ? "hidden" : "inline-block")} /><MinusSmIcon className={"h-6 w-6 " + (isOldOpen ? "inline-block" : "hidden")} /></div>
                <div className={isOldOpen ? "block" : "hidden"}>
                    {work.filter(n => n.frontmatter.old === true).map(renderNode)}
                </div>
            </div>
        </Layout>
        // <Lightbox project={project.slug} descriptions={descriptions} mainImage={project.frontmatter.mainImage} alignCenter={true} />
    );
};

export const query = graphql`
    query  {
        companies: allMdx(filter: {fields: {source: {eq: "company"}}}) {
            nodes {
                id
                frontmatter {
                    date
                    title
                    to
                    old
                    link
                }
                slug
                body
                fields {
                    source
                }
            }
        }
        projects: allMdx(filter: {fields: {source: {eq: "project"}}}) {
            nodes {
                id
                frontmatter {
                    title
                    date
                    old
                    company
                    tech {
                        icon
                        tech
                        url
                    }
                    imageDescriptions {
                        image
                        description
                    }
                    mainImage
                    link
                }
                slug
                body
                fields {
                    source
                }
            }
        }
    }
`;

export default WorkPage;
