import * as React from "react";
import Project from "./project";
import { MDXRenderer } from "gatsby-plugin-mdx";
// import { Link, useStaticQuery, graphql } from "gatsby";
// import { StaticImage } from "gatsby-plugin-image";

const Company = ({ company }) => {
    return (
        <article key={company.id} className={"bg-gray-50 shadow-md border pt-4 px-3 md:px-4 my-4 mx-2 md:mx-0" + (company.children.length > 0 ? " pb-1" : " pb-4")}>
            <div className="ml-1">
                <h2 className="font-bold text-2xl mb-4 text-main-dark">
                    <span className="italic text-sm mr-0.5 hidden">Company:</span> {company.frontmatter.title}
                </h2>
                <div className="text-justify">
                    <MDXRenderer className="text-justify">
                        {company.body}
                    </MDXRenderer>
                </div>
                {company.frontmatter.link && <p className="mt-4 text-gray-500"><span className="text-xs mr-0.5">Link:</span> <a href={company.frontmatter.link} className="hover:font-bold" target="_blank">{company.frontmatter.link}</a></p>}
                <p className={"text-gray-500" + (company.frontmatter.link ? "" : " mt-4")}><span className="text-xs mr-0.5">Years:</span> {company.time.getFullYear()} - {company.toTime ? company.toTime.getFullYear() : ""}</p>
            </div>

            {company.children &&
                <ul>
                    {company.children.map(n => (
                        <li key={n.slug}>
                            <Project project={n} />
                        </li>
                    ))}
                </ul>
            }
        </article>
    );
};

export default Company;
